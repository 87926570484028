import React from "react";
import { Typography, SvgIcon, useTheme } from "@material-ui/core";
import {
  DASHBOARD,
  SETTINGS,
  ORDERS,
  PRODUCTS,
  CUSTOMERS,
  MEDICAL_DIRECTORY
} from "../../../config/constants";
import { ReactComponent as DashboardIcon } from "../../../assets/icons/bx-category.svg";
import { ReactComponent as LogoutIcon } from "../../../assets/icons/bx-power-off.svg";
import { ReactComponent as PackageIcon } from "../../../assets/icons/bx-package.svg";
import { ReactComponent as ClientIcon } from "../../../assets/icons/bx-user-circle.svg";
import { ReactComponent as ProductsIcon } from "../../../assets/icons/bx-store.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/icons/bx-wrench.svg";
import { AuthDispatchContext } from "../../../context/Auth/Auth";
import {
    Wrapper,
    SidebarContainer,
    StyledLink,
    LogoutBtnContainer,

} from './Sidebar.style';

export const pageRoutes = [
  {
    name: "Dashboard",
    path: DASHBOARD,
    exact: true,
    icon: DashboardIcon,
  },
  {
    name: "Pedidos",
    path: ORDERS,
    exact: true,
    icon: PackageIcon,
  },
  {
    name: "Clientes",
    path: CUSTOMERS,
    exact: true,
    icon: ClientIcon,
  },
  {
    name: "Productos",
    path: PRODUCTS,
    exact: true,
    icon: ProductsIcon,
  },
  {
    name: "Directorio M.",
    path: MEDICAL_DIRECTORY,
    exact: true,
    icon: ProductsIcon,
  },
  {
    name: "Ajustes",
    path: SETTINGS,
    exact: false,
    icon: SettingsIcon,
  },
];

export default function Sidebar() {
  const authDispatch = React.useContext(AuthDispatchContext);
  const theme = useTheme();
  return (
    <Wrapper theme={theme}>
      <SidebarContainer>
        {pageRoutes.map((page, index) => (
          <li key={index}>
            <StyledLink
              to={page.path}
              
              exact={page.exact}
              activeClassName="selected"
              theme={theme}
            >
              <div>
                <SvgIcon component={page.icon} className="mr-3"></SvgIcon>
                <Typography>{page.name}</Typography>
              </div>
            </StyledLink>
          </li>
        ))}
      </SidebarContainer>
      <LogoutBtnContainer
        theme={theme}
        onClick={() => {
          authDispatch({ type: "LOGOUT" });
        }}
      >
        <div>
          <SvgIcon component={LogoutIcon} className="mr-3"></SvgIcon>{" "}
          <Typography>Salir</Typography>
        </div>
      </LogoutBtnContainer>
    </Wrapper>
  );
}
