import { ReactComponent as DeliveryIcon } from "../assets/icons/two_wheeler-24px.svg";
import { ReactComponent as PickupIcon } from "../assets/icons/bxs-car.svg";
import { ReactComponent as InstagramIcon } from "../assets/icons/bxl-instagram.svg";
import { ReactComponent as FacebookIcon } from "../assets/icons/bxl-facebook.svg";
import { ReactComponent as TwitterIcon } from "../assets/icons/bxl-twitter.svg";
import { ReactComponent as TelegramIcon } from "../assets/icons/bxl-telegram.svg";
import { ReactComponent as WebIcon } from "../assets/icons/bx-desktop.svg";
import { ReactComponent as AppIcon } from "../assets/icons/bx-mobile-alt.svg";

// **************** ROUTE CONSTANT START **************************
// General Page Section
export const DASHBOARD = "/";
export const PRODUCTS = "/productos";
export const CATEGORY = "/category";
export const LOGIN = "/login";
export const LOGOUT = "/logout";
export const ORDERS = "/orders";
export const CUSTOMERS = "/customers";
export const SETTINGS = "/settings";
export const STAFF_MEMBERS = "/admin-usuarios";
export const SITE_SETTINGS = "/site-settings";
export const SITE_CAROUSEL = "/site-settings/carousel";
export const SITE_NOTIFICATIONS = "/site-settings/notifications";
export const SITE_SECTIONS = "/site-settings/sections";
export const EDIT_SITE_SECTIONS = "/site-settings/edit-section";
export const TRASFERENCIA_BANCARIA = "/settings/metodos-pago/transferencias-bancarias";
export const PAGO_MOVIL = "/settings/metodos-pago/pago-movil";
export const SETTINGS_MOBILE_APP = "/settings/app-movil";
export const MOBILE_APP_CAROUSEL = "/site-settings/app-movil/carousel";
export const MEDICAL_DIRECTORY = "/medical-directory";
export const MOBILE_APP_VERSION = "/site-settings/app-movil/version";

// **************** ROUTE CONSTANT END **************************

export const ORIGEN_PEDIDO = [
  { tipo: "Web", icon: WebIcon },
  { tipo: "App móvil", icon: AppIcon },
];

export const TIPO_PEDIDO = [
  { tipo: "Delivery", icon: DeliveryIcon },
  { tipo: "Pick-up", icon: PickupIcon },
];
export const ESTADOS_PEDIDO = [
  { estado: "En verificación", class: "en-verificacion", instrucciones: "asegurarte que el pago se hizo correctamente." },
  { estado: "En proceso", class: "en-proceso", instrucciones: "asegurarte que todos los productos comprados por el cliente estén facturados y correctamente empacados." },
  { estado: "En camino", class: "en-camino", instrucciones: "asegurarte de darle la dirección correctamente a la persona del delivery, también podrías avisarle al cliente que su pedido está en camino." },
  { estado: "Lista para retirar", class: "en-camino", instrucciones: "asegurarte de informarle al cliente que la orden está lista para retirarla." },
  { estado: "Finalizada", class: "finalizada", instrucciones: "asegurarte de confirmar que el cliente recibió su pedido." },
  { estado: "Cancelado", class: "cancelada" },
];

export const ESTADOS_CLIENTES = [
  { estado: "Activo", class: "activo" },
  { estado: "Bloqueado", class: "bloqueado" },
];
export const ROLES_USUARIOS = [
  { rol: "super-admin", id: 1 },
  { rol: "administrador", id: 2 },
  { rol: "usuario", id: 3 }
];

export const REDES_SOCIALES = [
  { id: 1, tipo: "Facebook", icon: FacebookIcon },
  { id: 2, tipo: "Instagram", icon: InstagramIcon },
  { id: 3, tipo: "Twitter", icon: TwitterIcon },
  // { id: 4, tipo: "Telegram", icon: TelegramIcon },
  { id: 5, tipo: "Pagina web", icon: WebIcon },
  { id: 6, tipo: "App móvil", icon: AppIcon },
];

export const MESES = [
  { codigo: 1, titulo: "Enero", },
  { codigo: 2, titulo: "Febrero" },
  { codigo: 3, titulo: "Marzo" },
  { codigo: 4, titulo: "Abril" },
  { codigo: 5, titulo: "Mayo" },
  { codigo: 6, titulo: "Junio" },
  { codigo: 7, titulo: "Julio" },
  { codigo: 8, titulo: "Agosto" },
  { codigo: 9, titulo: "Septiembre" },
  { codigo: 10, titulo: "Octubre" },
  { codigo: 11, titulo: "Noviembre" },
  { codigo: 12, titulo: "Diciembre" },
];

export const findRedSocialIcon = (redId) => {

  let found = REDES_SOCIALES.find(
    element => element.id == redId
  )
  return found.icon;
}

