import styled from "styled-components";
import { NavLink } from "react-router-dom";
import {md} from '../../../theme/breakpoints';



export const Wrapper = styled.div`

  background-color: #fff;
  min-width: 100%;
  min-height:calc(100vh - 70px);
  padding-top: 2rem;
  padding-right: 0rem;
  padding-left: 2.5rem;
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.down(md)} {
   display:none;
  }

`;
export const SidebarContainer = styled.ul`
  
  list-style: none;
  margin: 0;
  padding: 0;
  flex: 1;
`;

export const StyledLink = styled(NavLink)`
  height: 60px;

  margin-bottom: 1rem;
  border-radius: 30px 0px 0px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    text-decoration: none;
  }
  div {
    display: flex;
    align-items: center;
    min-width: 150px;
    p {
      // color: #fff;
    }
  }
  svg {
    color: ${(props) => props.theme.palette.primary.main};
  }
  &.selected {
    background-color: ${(props) => props.theme.palette.primary.light};
    div {
      p {
        color: #fff;
      }
    }
    svg {
      color: #fff;
    }
  }
`;

export const LogoutBtnContainer = styled.div`
  height: 60px;
  margin-bottom: 1rem;
  border-radius: 30px 0px 0px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  div {
    display: flex;
    align-items: center;
    min-width: 150px;
    p {
      // color: #fff;
    }
  }
  svg {
    color: ${(props) => props.theme.palette.primary.main};
  }
  &.selected {
    background-color: ${(props) => props.theme.palette.primary.light};
    div {
      p {
        color: #fff;
      }
    }
    svg {
      color: #fff;
    }
  }
`;