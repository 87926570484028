import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";
import { AuthMachineContextProvider } from "./context/Auth/Auth";
function App() {
  return (
    <div className="App">
      <AuthMachineContextProvider>
        <Router>
          <Routes />
        </Router>
      </AuthMachineContextProvider>
    </div>
  );
}

export default App;
