import React, { lazy, Suspense, useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "./components/Layout/Layout";
import Login from "./pages/Auth/Login";
import LoadingSpinner from "./components/Loading/LoadingSpinner";
import { AuthStateContext } from "./context/Auth/Auth";
import { MobileDrawerContextProvider } from "./context/MobileDrawer/MobileDrawer";
import { ReusableDrawerContextProvider } from "./context/ReusableDrawer/reusable-drawer";
import ReusableDrawer from "./components/Common/reusable-drawer";
import {
  PAGO_MOVIL,
  CUSTOMERS,
  SITE_SETTINGS,
  SITE_CAROUSEL,
  SITE_SECTIONS,
  SITE_NOTIFICATIONS,
  EDIT_SITE_SECTIONS,
  SETTINGS_MOBILE_APP,
  MOBILE_APP_CAROUSEL,
  MEDICAL_DIRECTORY,
  MOBILE_APP_VERSION

} from "./config/constants";

const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
const Users = lazy(() => import("./pages/StaffMembers/StaffMembers"));
const Config = lazy(() => import("./pages/Settings/Settings"));
const Products = lazy(() => import("./pages/Products/Products"));
const Orders = lazy(() => import("./pages/Orders/Orders"));
const Notificaciones = lazy(() =>
  import("./pages/SiteSettings/Notifications/Notifications")
);
const PaymentMethods = lazy(() =>
  import("./pages/PaymentsMethods/PaymentMethods")
);
const BankAccounts = lazy(() => import("./pages/BankAccounts/BankAccounts"));
const PagoMovilAccounts = lazy(() =>
  import("./pages/PagoMovilAccounts/PagoMovilAccounts")
);
const Customers = lazy(() => import("./pages/Customers/Customers"));
const SiteSettings = lazy(() => import("./pages/SiteSettings/SiteSettings"));
const Carousel = lazy(() => import("./pages/SiteSettings/Carousel/Carousel"));
const Sections = lazy(() => import("./pages/SiteSettings/Sections/Sections"));
const EditSection = lazy(() =>
  import("./pages/SiteSettings/Sections/EditSection/EditSection")
);
const MobileAppSettings = lazy(() =>
  import("./pages/Settings/MobileApp/MobileApp")
);
const MobileAppCarousel = lazy(() =>
  import("./pages/Settings/MobileApp/Carousel/Carousel")
);
const MedicalDirectory = lazy(() =>
  import("./pages/MedicalDirectory/MedicalDirectory")
);

function PrivateRoute({ component: Component, hasDrawer = false, ...rest }) {
  const authState = useContext(AuthStateContext);
  return (
    <Route
      {...rest}
      render={(props) =>
        !authState.matches("LoggedOut") ? (
          hasDrawer ? (
            <ReusableDrawerContextProvider>
              <MobileDrawerContextProvider>
                <AdminLayout>
                  <Component {...props} />
                </AdminLayout>
              </MobileDrawerContextProvider>
              <ReusableDrawer />
            </ReusableDrawerContextProvider>
          ) : (
            <MobileDrawerContextProvider>
              <AdminLayout>
                <Component {...props} />
              </AdminLayout>
            </MobileDrawerContextProvider>
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        )
      }
    />
  );
}
const Routes = () => {
  return (
    <Suspense
      fallback={
        <div className={`col-md-12 d-flex justify-content-center`}>
          {" "}
          <LoadingSpinner />
        </div>
      }
    >
      <Switch>
        <PrivateRoute exact path="/" component={Dashboard} />
        <PrivateRoute
          exact
          path="/settings/admin-usuarios"
          component={Users}
          hasDrawer={true}
        />
        <PrivateRoute
          exact
          path="/orders"
          component={Orders}
          hasDrawer={true}
        />
        <PrivateRoute
          exact
          path="/productos"
          component={Products}
          hasDrawer={true}
        />
        <PrivateRoute
          exact
          path="/settings"
          component={Config}
          hasDrawer={true}
        />
        <PrivateRoute
          exact
          path="/settings/metodos-pago"
          component={PaymentMethods}
        />
        <PrivateRoute
          exact
          path="/settings/metodos-pago/transferencias-bancarias"
          component={BankAccounts}
          hasDrawer={true}
        />
        <PrivateRoute
          exact
          path={PAGO_MOVIL}
          component={PagoMovilAccounts}
          hasDrawer={true}
        />
        <PrivateRoute
          exact
          path={CUSTOMERS}
          component={Customers}
          hasDrawer={true}
        />
        <PrivateRoute
          exact
          path={SITE_SETTINGS}
          component={SiteSettings}
          hasDrawer={true}
        />
        <PrivateRoute
          exact
          path={SITE_CAROUSEL}
          component={Carousel}
          hasDrawer={true}
        />
        <PrivateRoute
          exact
          path={SITE_NOTIFICATIONS}
          component={Notificaciones}
          hasDrawer={true}
        />
        <PrivateRoute
          exact
          path={SITE_SECTIONS}
          component={Sections}
          hasDrawer={true}
        />
        <PrivateRoute
          path={EDIT_SITE_SECTIONS + "/:section_id"}
          component={EditSection}
          hasDrawer={true}
        />
        <PrivateRoute
          exact
          path={SETTINGS_MOBILE_APP}
          component={MobileAppSettings}
          hasDrawer={true}
        />
        <PrivateRoute
          exact
          path={MOBILE_APP_CAROUSEL}
          component={MobileAppCarousel}
          hasDrawer={true}
        />
        <PrivateRoute
          exact
          path={MEDICAL_DIRECTORY}
          component={MedicalDirectory}
          hasDrawer={true}
        />
        <PrivateRoute
          exact
          path={MOBILE_APP_VERSION}
          component={MobileAppCarousel}
          hasDrawer={true}
        />
        <Route exact path="/login">
          <Login />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default Routes;
