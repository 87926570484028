import { createMuiTheme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

// Create a theme instance.
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#00A2C9",
      light: "#00B9E5",
    },
    secondary: {
      main: '#CCD40D',
    },
    error: {
      main: red.A400,
      dark:'#C81336'
    },
    background: {
      default: "#fff",
    },
    success:{
      main:'#009E43',
      dark:'#007632'
    },
    warning:{
      main:'#F2B600',
      dark:'#DAA502'
    }
  },
  typography: {
    allVariants: {
      color: "#455557",
    },
    fontFamily: '"Poppins", sans-serif',
    h4: {
      fontSize: "1.8rem",
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: "white",
      },
    },
  },
});

export default theme;
